body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Noto Sans JP", sans-serif;
}

body {
  background-color: #3f3f3f;
}

.app {
  width: 100%;
  max-width: 43rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: white;
  margin: 3rem auto;
}

.card {
  width: 100%;
  padding: 30px 90px 90px 9px;
  margin-bottom: 10rem;
  border: 6px solid rgba(0, 0, 0, 0.3);
  box-shadow: 20px 20px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  position: relative;
}

.card h2 {
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.8rem;
  text-transform: uppercase;
}

.card .row {
  position: relative;
  width: 100%;
  display: grid;
  grid: auto / auto auto;
  margin: 0.3rem;
  /* grid-template-columns: repeat(auto-fit, minmax(40%, 1fr)); */
  grid-gap: 1px;
}

.card .row .col {
  position: relative;
  /* width: 50%; */
  margin: 1rem 20px 6rem 0;
  transition: 0.5s;
}

.card .row #message {
  width: 120%;
  margin-bottom: 2rem;
}

#message {
  margin-bottom: 2rem;
}

.card .row input,
.card .row textarea {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: x-large;
  padding: 2rem 0 2rem 2rem;
  border: 5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  color: #000;
}

.card .row textarea {
  position: absolute;
  width: 47%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 24px;
  padding: 10px 0 0.8rem 20px;
  margin-bottom: 1rem;
  border: 5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.3);
  color: #000;
  border-radius: 50px;
}

/* .card .row .form-group label {
  line-height: 40px;
  color: #ffffff;
  font-size: 24px;
  margin: 0 0 0 30px;
  display: block;
  pointer-events: none;
} */

/* .row .col:nth-child(6) {
  margin-top: 64px;
} */

.card .row .form-group input:focus,
.card .row .form-group textarea:focus {
  border: 5px solid #ffffff;
  transition: all 0.5s;
}

/* .card .row button {
  border: 5px solid rgba(0, 0, 0, 0.3);
  box-shadow: 10px 10px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  height: 100%;
  width: 50%;
  cursor: pointer;
  outline: none;
  background: transparent;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 40px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 45px;
  transition: all 0.4s;
}

.card .row button:hover {
  border: 5px solid rgba(255, 255, 255, 1);
  color: #ffffff;
  transition: all 0.4s;
} */

/* textarea {
  width: 50%;
}

.form-control {
  margin-bottom: 1rem;
  margin: 5px;
}

.form-control input,
.form-control select {
  font: inherit;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 20rem;
  max-width: 100%;
  font-size: medium;
  margin-left: 2px;
}

.form-control input:focus {
  outline: none;
  border-color: #240370;
  background-color: #d3f5e1;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.control-group .form-control {
  flex: 1;
  width: 50%;
}
.control-group button {
  font: inherit;
  background-color: #240370;
  color: white;
  border: 1px solid #240370;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #33059e;
  border-color: #33059e;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.form-actions {
  text-align: right;
}

.form-actions button {
  margin-left: 1rem;
}



.error-text {
  color: #cb9f9f;
  font-size: large;
} */
.card .form-actions button {
  display: flex;
  border: 0.5rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.3);
  padding: 10px;
  cursor: pointer;
  outline: none;
  background: transparent;
  text-transform: uppercase;
  color: #ffffff;
  line-height: 40px;
  font-size: 24px;
  font-weight: 700;
  border-radius: 45px;
  transition: all 0.4s;
  font-size: small;
  color: #7c7c7c;
}

.card .form-actions button:hover {
  border: 5px solid rgba(255, 255, 255, 1);
  color: #ffffff;
  transition: all 0.4s;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.invalid input {
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.form-actions {
  position: absolute;
  margin-right: 0.2rem;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 480px) {
  .card h2 {
    font-size: 1.2rem;
  }

  .card .row #message {
    width: 100%;
  }

  .card .row input,
  .card .row textarea {
    font-size: small;
    border: 3px solid rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .form-actions {
    margin-right: 0.4rem;
  }
}
@media screen and (max-width: 1080px) {
  .card {
    max-width: 100%;
  }

  .card h2 {
    color: rgba(0, 0, 0, 0.3);
    font-size: 1.2rem;
    text-transform: uppercase;
  }

  .card .row #message {
    width: 120%;
  }

  .card .row input,
  .card .row textarea {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    font-size: small;
    padding: 2rem 0 2rem 2rem;
    border: 3px solid rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #000;
  }

  .form-actions {
    position: absolute;
    margin-right: 0.4rem;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: end;
  }

  .card .form-actions button {
    padding: 1px;
    margin-right: 1rem;
  }
}
