.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
    height: auto;
  }
}

img {
  width: 10%;
}

h3 {
  padding-top: 10px;
  margin-bottom: 3rem;
}

@media (max-width: 480px) {
  .App-logo {
    width: 30%;
    padding-top: 2rem;
  }
}

.cardlogo {
  float: right;
  padding-right: 3px;
}

@media (min-width: 481px) {
  .App-logo {
    width: 10%;
  }
}

.inputbox {
  padding: 5px;
}

.card {
  flex: auto;
  background-color: #6a6f6aca;
  padding: 8px;
  font-size: 25px;
  border-radius: 10px;
  text-align: start;
}

@media (min-width: 481px) {
  .card {
    width: 50%;
  }
}
@media (max-width: 480px) {
  .card {
    width: 92%;
  }
}

.oneLine {
  display: flex;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 255, 255);
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
} */
